<template>
  <div>
    <v-data-table :headers="headers" :items="transactions" dense>
      <template v-slot:[`item.package`]="{ item }">
        <div class="text-start" :key="item.id">
          {{ item.module.name }}
        </div>
      </template>
      <template v-slot:[`item.amountPaid`]="{ item }">
        <div class="text-end" :key="item.id">
          {{ formatCurrency(item.amountPaid) }}
        </div>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <div
          v-if="item.plan"
          class="text-center"
          :class="
            dateIsAfter(
              addDaysToDate({ date: item.date, days: item.plan.duration })
            )
              ? ''
              : 'error--text'
          "
          :key="item.id"
        >
          {{
            dateFormat(
              addDaysToDate({ date: item.date, days: item.plan.duration })
            )
          }}
        </div>
      </template>
      <template v-slot:[`item.value`]="{ item }">
        <div class="text-end" :key="item.id">
          {{ formatCurrency(item.value) }}
        </div>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div
          class="d-flex justify-center align-center text-center"
          :key="item.id"
        >
          <v-switch
            v-model="item.status"
            :error="!item.status"
            :success="item.status"
            class="mt-1"
            dense
            hide-details
            @change="updateTransactionStatus(item)"
          ></v-switch>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="text-right" :key="item.id">
          <v-btn
            color="primary"
            x-small
            height="30px"
            class="ma-1"
            dark
            @click="openPaymentDialog(item)"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            color="error"
            x-small
            height="30px"
            class="ma-1"
            dark
            @click="openConfirmeDialog(item.id)"
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="openUpdateDate(true, item.id)">
                <v-list-item-title>
                  <v-icon small>mdi-plus</v-icon> days
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="openUpdateDate(false, item.id)">
                <v-list-item-title>
                  <span class="px-1">-</span> days
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>

    <confirm-dialog
      :dialog="confirm"
      :id="removeId"
      :question="`Pretende remover a transação?`"
      @yes="deleteTransaction()"
      @no="confirm = false"
    />
    <error-dialog
      :dialog="showError"
      :message="error"
      @close="showError = false"
    />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
    <update-date />
  </div>
</template>

<script>
import {
  DELETE_ENTITY_MODULE_MUTATION,
  UPDATE_ENTITY_MODULE_STATUS_MUTATION,
} from "../graphql/Mutation";
import addDaysToDateMixins from "@/mixins/add-days-to-date";
import compareDatesMixins from "@/mixins/compare-dates";
import formatCurrencyMixins from "@/mixins/format-currency";
import dateFormatMixins from "@/mixins/date-format";
import { formatError } from "@/utils";
import ConfirmDialog from "./../../../components/ConfirmDialog.vue";
import ErrorDialog from "./../../../components/ErrorDialog.vue";
import ProgressDialog from "./../../../components/ProgressDialog.vue";
import SuccessDialog from "./../../../components/SuccessDialog.vue";
import dialogMixins from "@/mixins/dialog";
import UpdateDate from "./UpdateDate.vue";
export default {
  name: "FinanceTable",
  components: {
    ConfirmDialog,
    ErrorDialog,
    ProgressDialog,
    SuccessDialog,
    UpdateDate,
  },
  props: {
    transactions: {
      type: Array,
    },
  },
  mixins: [
    addDaysToDateMixins,
    compareDatesMixins,
    dateFormatMixins,
    dialogMixins,
    formatCurrencyMixins,
  ],
  data: () => ({
    error: undefined,
    isLoading: false,
    showError: false,
    showSuccess: false,
    success: undefined,
  }),
  computed: {
    headers() {
      return [
        { text: "Código", align: "start", sortable: false, value: "code" },
        { text: "Pacode", align: "start", value: "package" },
        { text: "Nº Utilizadores", align: "center", value: "totalUsers" },
        { text: "Nº Candidatos", align: "center", value: "totalProfiles" },
        { text: "Valor a Pagar", align: "end", value: "value" },
        { text: "Valor Pago", align: "end", value: "amountPaid" },
        { text: "Data de validade", align: "center", value: "date" },
        { text: "Estado", align: "center", value: "status" },
        { text: "Acções", align: "right", value: "actions", sortable: false },
      ];
    },
  },

  methods: {
    openUpdateDate(isAdd, transactionId) {
      // eslint-disable-next-line no-undef
      Fire.$emit("updateDateDialog", { isAdd, transactionId });
    },
    async deleteTransaction() {
      this.confirm = false;
      this.isLoading = true;
      try {
        await this.$apollo.mutate({
          mutation: DELETE_ENTITY_MODULE_MUTATION,
          variables: {
            id: this.removeId,
          },
        });
        // this.removeEntityState(this.removeId)
        this.$emit("remove", this.removeId);
        this.success = "Transação removida com sucesso!";
        this.showSuccess = true;
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
    openConfirmeDialog(id) {
      this.removeId = id;
      this.confirm = true;
    },
    openPaymentDialog(transaction) {
      // eslint-disable-next-line no-undef
      Fire.$emit("paymentDialog", transaction);
    },
    async updateTransactionStatus(transaction) {
      try {
        this.isLoading = true;
        const { data } = await this.$apollo.mutate({
          mutation: UPDATE_ENTITY_MODULE_STATUS_MUTATION,
          variables: {
            id: transaction.id,
            status: transaction.status,
          },
        });

        // eslint-disable-next-line no-undef
        Fire.$emit("updateTransaction", data.updateEntityModuleStatus);
        this.dialog = false;
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>