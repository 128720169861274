<template>
  <v-dialog v-model="dialog" persistent max-width="200px">
    <v-card>
      <v-card-title class="justify-center">
        {{ isAdd ? "Add Days" : "Remove days" }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form" @submit.prevent="isAdd ? add() : subtract()">
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field v-model.number="days" type="number" dense outlined>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex flex-row align-center">
            <v-btn color="primary" outlined small class="mx-2" @click="close()"
              >Close</v-btn
            >
            <v-btn
              :disabled="isLoading || days === 0"
              small
              type="submit"
              color="primary"
              >Salvar</v-btn
            >
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  ADD_DAYS_TO_TRANSACTIONS_MUTATION,
  SUBTRACT_DAYS_FROM_TRANSACTIONS_MUTATION,
} from "../graphql/Mutation";
export default {
  name: "UpdateDate",

  data: () => ({
    dialog: false,
    transactionId: undefined,
    isAdd: true,
    days: 0,
    isLoading: false,
  }),

  created() {
    // eslint-disable-next-line no-undef
    Fire.$on("updateDateDialog", ({ isAdd, transactionId }) => {
      this.transactionId = transactionId;
      this.isAdd = isAdd;
      this.dialog = true;
    });
  },

  methods: {
    async add() {
      this.isLoading = true;
      try {
        const { data } = await this.$apollo.mutate({
          mutation: ADD_DAYS_TO_TRANSACTIONS_MUTATION,
          variables: {
            id: this.transactionId,
            days: this.days,
          },
        });

        // eslint-disable-next-line no-undef
        Fire.$emit("updateTransaction", data.addDaysToTransactions);
        this.dialog = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    async subtract() {
      this.isLoading = true;
      try {
        const { data } = await this.$apollo.mutate({
          mutation: SUBTRACT_DAYS_FROM_TRANSACTIONS_MUTATION,
          variables: {
            id: this.transactionId,
            days: this.days,
          },
        });

        // eslint-disable-next-line no-undef
        Fire.$emit("updateTransaction", data.subtractDaysFromTransactions);
        this.dialog = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>