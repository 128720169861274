<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title class="justify-center">
        Edit transaction
      </v-card-title>
      <v-card-text>
        <v-form ref="form" @submit.prevent="handleSubmit">
          <v-row>
            <v-col cols="12" md="12">
              <v-currency-field
                v-model.number="transaction.value"
                label="Price Users"
                placeholder="500,000.00"
                readonly
                dense
                outlined
                hide-details
              />
            </v-col>
            <v-col cols="12" md="12">
              <v-currency-field
                v-model.number="amountPaid"
                label="Price Users"
                placeholder="500,000.00"
                dense
                outlined
                hide-details
              />
            </v-col>
            <!-- <v-col cols="12" md="12">
              <div class="d-flex justify-end">
                <v-switch
                  v-model.number="transaction.status"
                  :error="transaction.status ? false : true"
                  :success="transaction.status ? true : false"
                  :value="true"
                  inset
                  class="mt-0"
                ></v-switch>
              </div>
            </v-col> -->
          </v-row>
          <v-row class="d-flex flex-row justify-end align-center mt-5 px-3">
            <v-btn
              color="primary"
              outlined
              class="mx-2"
              @click="close()"
              >Close</v-btn
            >
            <v-btn
              :disabled="isLoading"
              type="submit"
              color="primary"
              >Salvar</v-btn
            >
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <error-dialog :dialog="showError" :message="error" @close="showError = false" />
    <progress-dialog :processing="isLoading" />
    <success-dialog :dialog="showSuccess" :message="success" @close="showSuccess = false" />
  </v-dialog>
</template>

<script>
import {
  UPDATE_ENTITY_MODULE_AMOUNT_PAID_MUTATION
} from '../graphql/Mutation';
import { formatError } from '@/utils'
import ErrorDialog from './../../../components/ErrorDialog.vue'
import ProgressDialog from './../../../components/ProgressDialog.vue'
import SuccessDialog from './../../../components/SuccessDialog.vue'
export default {
  name: 'PaymentDialog',
  components: { ErrorDialog, ProgressDialog, SuccessDialog },
  data: () => ({
    amountPaid: 0,
    dialog: false,
    id: null,
    error: undefined,
    isLoading: false,
    showError: false,
    showSuccess: false,
    success: undefined,
    transaction: {
      amountPaid: 0,
      status: false,
      value: 0
    },
  }),

  created () {
    // eslint-disable-next-line no-undef
    Fire.$on('paymentDialog', (transaction) => {
      this.dialog = true;
      this.id = transaction.id;
      this.amountPaid = transaction.amountPaid;
      this.transaction = {
        amountPaid: 0,
        status: transaction.status,
        value: transaction.value
      }
    })
  },
  

  methods: {
    close () {
      this.dialog = false;
    },
    async handleSubmit () {
      try {
        this.isLoading = true;
        const { data } = await this.$apollo.mutate({
          mutation: UPDATE_ENTITY_MODULE_AMOUNT_PAID_MUTATION,
          variables: {
            id: this.id,
            amountPaid: this.amountPaid,
          }
        });
        
        // eslint-disable-next-line no-undef
        Fire.$emit('updateTransaction', data.updateEntityModuleAmountPaid);
        this.dialog = false;
      } catch (error) {
        this.error = formatError(error.message)
        this.showError = true
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>